<template>
  <div class="servers">
    <h1 class="title">SERVERS</h1>
    <StatsBanner />
    <ServerList />
  </div>
</template>

<script>
import StatsBanner from '@/components/StatsBanner.vue'
import ServerList from '@/components/ServerList.vue'

export default {
  name: 'Servers',
  components: {
    StatsBanner,
    ServerList
  }
}
</script>
