<template>
  <section class="list">
    <Filter>
      <FilterButtons>
        <input v-model="filter.search.value" type="text" class="search" placeholder="Search..." />
        <div class="break"/>
        <FilterButton :filter="filter" option-name="regions" class="regions">Regions</FilterButton>
        <FilterButton :filter="filter" option-name="modes" class="modes">Modes</FilterButton>
        <FilterButton :filter="filter" option-name="players" class="players">Player count</FilterButton>
        <FilterButton :filter="filter" option-name="privacy" class="privacy">Server type</FilterButton>
        <FilterButton :filter="filter" option-name="levelLock" class="levelLock">Level lock</FilterButton>
      </FilterButtons>

      <FilterOptions v-show="filter.regions.open">
        <FilterOption v-for="region in $store.getters.regions" :key="region">
          <input :id="region" type="checkbox" v-model="filter.regions.value" :value="region">
          <label :for="region"><span>{{ region }}</span></label>
        </FilterOption>
      </FilterOptions>

      <FilterOptions v-show="filter.modes.open">
        <FilterOption v-for="mode in $store.getters.modes" :key="mode">
          <input :id="mode" type="checkbox" v-model="filter.modes.value" :value="mode">
          <label :for="mode"><span>{{ mode }}</span></label>
        </FilterOption>
      </FilterOptions>

      <FilterOptions v-show="filter.players.open">
        <FilterOption>
          <Slider v-model="filter.players.value" :min="0" :max="10"/>
        </FilterOption>
      </FilterOptions>

      <FilterOptions v-show="filter.privacy.open">
        <FilterOption>
          <input id="privacyPrivate" type="checkbox" v-model="filter.privacy.value" value="Private servers">
          <label for="privacyPrivate"><span>Private servers</span></label>
        </FilterOption>

        <FilterOption>
          <input id="privacyPublic" type="checkbox" v-model="filter.privacy.value" value="Public servers">
          <label for="privacyPublic"><span>Public servers</span></label>
        </FilterOption>
      </FilterOptions>

      <FilterOptions v-show="filter.levelLock.open">
        <FilterOption>
          <Slider v-model="filter.levelLock.value" :min="0" :max="100" :step="10"/>
        </FilterOption>
      </FilterOptions>

      <FilterTags :filter="filter" />

      <FilterButtons>
        <div @click="$refs.sortBy.focus()" class="sortBy__title">Sort by:</div>
        <select v-model="filter.sortBy.value" class="sortBy" ref="sortBy">
          <option value="name">Name</option>
          <option value="region">Region</option>
          <option value="map">Map</option>
          <option value="playerCount">Player count</option>
          <option value="averageLevel">Average level</option>
        </select>
      </FilterButtons>
    </Filter>

    <div class="list__stats">
      <span>Number of matching servers: </span>
      <b>{{ servers.length }}</b>
    </div>

    <div class="list__items">
      <ServerListItem v-for="server in servers" :key="server.id" :server="server" />
    </div>
  </section>
</template>

<script>
import Slider from '@vueform/slider'

import Filter from '@/components/filter/Filter.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import FilterButtons from '@/components/filter/FilterButtons.vue'
import FilterOptions from '@/components/filter/FilterOptions.vue'
import FilterOption from '@/components/filter/FilterOption.vue'
import FilterTags from '@/components/filter/FilterTags.vue'
import ServerListItem from '@/components/ServerListItem.vue'

export default {
  name: 'ServerList',
  components: {
    Filter,
    FilterButtons,
    FilterButton,
    FilterOptions,
    FilterOption,
    FilterTags,
    ServerListItem,
    Slider
  },
  props: {
    msg: String
  },
  data () {
    return {
      filter: {
        version: '4',
        search: {
          value: '',
          type: 'text',
          default: '',
          open: false
        },
        regions: {
          value: [],
          type: 'checkbox',
          default: [],
          open: false
        },
        modes: {
          value: [],
          type: 'checkbox',
          default: [],
          open: false
        },
        players: {
          value: [1, 10],
          type: 'slider',
          default: [0, 10],
          open: false
        },
        privacy: {
          value: [],
          type: 'checkbox',
          default: [],
          open: false
        },
        levelLock: {
          value: [0, 100],
          type: 'slider',
          default: [0, 100],
          open: false
        },
        sortBy: {
          value: 'averageLevel',
          type: 'text',
          default: 'averageLevel'
        }
      }
    }
  },
  computed: {
    servers () {
      // Handle server data not set
      if (!this.$store || !this.$store.getters.servers) {
        return {}
      }

      // Make variable of filtered servers
      let filteredServers = this.$store.getters.servers

      // Handle search filter active
      if (this.filter.search && this.filter.search.value) {
        // Convert search result to lower case
        const searchTerm = this.filter.search.value.toLowerCase()

        // Filter by search
        filteredServers = filteredServers.filter((server) => {
          // Check if search matches server name
          if (server.name.toLowerCase().includes(searchTerm)) {
            return true
          }

          // Check if search matches a player tag or name
          if (server.players) {
            for (const player of server.players) {
              if (player.tag.toLowerCase().includes(searchTerm) || player.name.toLowerCase().includes(searchTerm)) {
                return true
              }
            }
          }

          // Return no matching results
          return false
        })
      }

      // Handle region filter active
      if (this.filter.regions && this.filter.regions.value.length) {
        // Filter by region
        filteredServers = filteredServers.filter((server) => {
          // Check if region selection matches server region
          return this.filter.regions.value.includes(server.region)
        })
      }

      // Handle mode filter active
      if (this.filter.modes && this.filter.modes.value.length) {
        // Filter by mode
        filteredServers = filteredServers.filter((server) => {
          // Check if mode selection matches server mode
          return this.filter.modes.value.includes(server.mode)
        })
      }

      // Handle players filter active
      if (this.filter.players && (this.filter.players.value[0] > 0 || this.filter.players.value[1] < 10)) {
        // Filter by player count
        filteredServers = filteredServers.filter((server) => {
          // Get player count
          const playerCount = server.players ? server.players.length : 0

          // Check if player count is in range
          return playerCount >= this.filter.players.value[0] && playerCount <= this.filter.players.value[1]
        })
      }

      // Handle privacy filter active
      if (this.filter.privacy && this.filter.privacy.value.length) {
        // Filter by privacy
        filteredServers = filteredServers.filter((server) => {
          // Check if privacy selection matches server privacy
          return (this.filter.privacy.value.includes('Private servers') && server.password) || (this.filter.privacy.value.includes('Public servers') && !server.password)
        })
      }

      // Handle levelLock filter active
      if (this.filter.levelLock && (this.filter.levelLock.value[0] > 0 || this.filter.levelLock.value[1] < 100)) {
        // Filter by player count
        filteredServers = filteredServers.filter((server) => {
          // Hande no server lock
          if (server.maxLevel === 0) {
            return false
          }

          // Check if level lock is in range
          return server.minLevel >= this.filter.levelLock.value[0] && server.maxLevel <= this.filter.levelLock.value[1]
        })
      }

      // Sort players in all servers
      for (const server of filteredServers) {
        // Handle server has no players
        if (!server.players) {
          continue
        }

        // Sort players by team
        server.players.sort((a, b) => a.team < b.team ? 1 : -1)
      }

      // Sort servers by selecter sort parameter
      filteredServers.sort((a, b) => {
        switch (this.filter.sortBy.value) {
          case 'name':
            if (a.name < b.name) {
              return -1
            }

            if (a.name > b.name) {
              return 1
            }

            return 0

          case 'region':
            if (a.region < b.region) {
              return -1
            }

            if (a.region > b.region) {
              return 1
            }

            return 0

          case 'map':
            if (a.map < b.map) {
              return -1
            }

            if (a.map > b.map) {
              return 1
            }

            return 0

          case 'playerCount':
            if (a.players.length < b.players.length) {
              return 1
            }

            if (a.players.length > b.players.length) {
              return -1
            }

            return 0

          case 'averageLevel':
            if (a.average < b.average) {
              return 1
            }

            if (a.average > b.average) {
              return -1
            }

            return 0
        }
      })

      return filteredServers
    }
  },
  mounted () {
    // Handle filter is set in local storage
    if (localStorage.serverFilter) {
      // Get stored filter
      const filter = JSON.parse(localStorage.serverFilter)

      // Check if filter version is up to date
      if (filter.version && filter.version === this.filter.version) {
        // Overwrite filter with stored filter
        this.filter = filter
      }
    }

    // Fetch server data
    this.$store.dispatch('fetchServerData')
  },
  watch: {
    filter: {
      deep: true,
      handler (newFilter) {
        // Store new filter settings
        localStorage.serverFilter = JSON.stringify(newFilter)
      }
    }
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped lang="scss">
.list {
  .search {
    flex: 2;
  }

  .regions {
    flex: 1;
  }

  .modes {
    flex: 1;
  }

  .players {
    flex: 1;
  }

  .privacy {
    flex: 1;
  }

  .levelLock {
    flex: 1;
  }

  .sortBy {
    flex: 0.2;
    border-left: none;
    padding-left: 65px;
    background-color: transparent;

    &__title {
      display: flex;
      align-items: center;
      position: absolute;
      z-index: -1;
    }
  }

  @media(max-width: 750px) {
    .list__item__info {
      flex-wrap: wrap;
    }

    .search {
      border-right: solid 1px #000;
      border-bottom: none;
    }

    .sortBy {
      flex: 1
    }

    .title {
      font-weight: bold;
      margin-bottom:  0.5rem;
    }

    .break {
      display: block;
      width: 100%;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
    }
  }
}
</style>
