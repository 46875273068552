<template>
  <div class="loaderWrapper">
    <div class="loader" v-show="$store.getters.fetching"></div>
    <div class="loadButton" v-show="!$store.getters.fetching" tabindex="0" @click="$store.dispatch('fetchServerData')"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    playing: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  watch: {
    '$store.getters.fetching': {
      handler (newValue) {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;

  &:after {
    content: "";
    display: block;
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loader 1.2s linear infinite;
  }

}

.loadButton {
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  border: 2px solid #fff;

  &:hover {
    cursor: pointer;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
