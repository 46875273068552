<template>
  <div class="serverStats">
    <div class="serverStats__title">
      <Loader class="spinner" />
      <span class="title">// QUICK STATS</span>
    </div>
    <div class="serverStats__content">
      <div class="stat">Players: <b>{{ playerCount }}</b></div>
      <div class="stat">Most played: <b>{{ mostPlayedMap.name }}&nbsp;({{ mostPlayedMap.count }})</b></div>
      <div class="break" />
      <div class="stat">Avg. level: <b>{{ averageLevel }}</b></div>
      <div class="stat">Dist. of levels: Low&nbsp;<b>{{ playerLevelDistribution.low }}</b> / Medium&nbsp;<b>{{ playerLevelDistribution.medium }}</b> / Medium+&nbsp;<b>{{ playerLevelDistribution.mediumHigh }}</b> / High&nbsp;<b>{{ playerLevelDistribution.high }}</b></div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
  name: 'ServersStats',
  components: {
    Loader
  },
  computed: {
    playerCount () {
      // Handle server data not set
      if (!this.$store || !this.$store.getters.players) {
        return '–'
      }

      return this.$store.getters.players.length
    },
    averageLevel () {
      // Handle server data not set
      if (!this.$store || !this.$store.getters.players || this.$store.getters.players.length === 0) {
        return '–'
      }

      let totalLevel = 0

      for (const player of this.$store.getters.players) {
        totalLevel += player.level
      }

      const average = totalLevel / this.$store.getters.players.length
      const averageRounded = Math.round(average * 100) / 100

      return averageRounded
    },
    playerLevelDistribution () {
      // Handle server data not set
      if (!this.$store || !this.$store.getters.players || this.$store.getters.players.length === 0) {
        return {
          low: '–',
          medium: '–',
          mediumHigh: '–',
          high: '–'
        }
      }

      const levelDistribution = {
        low: 0,
        medium: 0,
        mediumHigh: 0,
        high: 0
      }

      for (const player of this.$store.getters.players) {
        if (player.level >= 75) {
          levelDistribution.high++
        } else
        if (player.level >= 50) {
          levelDistribution.mediumHigh++
        } else
        if (player.level >= 25) {
          levelDistribution.medium++
        } else {
          levelDistribution.low++
        }
      }

      return levelDistribution
    },
    mostPlayedMap () {
      if (!this.$store || !this.$store.getters.servers) {
        return 0
      }

      const maps = []

      // Loop through servers
      for (const server of this.$store.getters.servers) {
        // Skip server if empty
        if (server.players.length < 0) {
          continue
        }

        let mapFound = false

        // Loop through maps
        for (const map of maps) {
          // Handle map matches current map
          if (map.name === server.map) {
            // Add to map count
            map.count++
            mapFound = true
            break
          }
        }

        // Handle map not in list
        if (!mapFound) {
          maps.push({
            name: server.map,
            count: 1
          })
        }
      }

      let mostPlayed = {
        name: '–',
        count: 0
      }

      // Loop through maps
      for (const map of maps) {
        // Set current map as most played if more played than current best
        if (map.count > mostPlayed.count) {
          mostPlayed = map
        }
      }

      return mostPlayed
    }
  }
}
</script>

<style lang="scss" scoped>
  .serverStats {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #333;
    color: #fff;

    &__title {
      display: flex;
    }

    &__content {
      display: flex;
      flex: 1;
    }

    @media(max-width: 1100px) {
      flex-direction: column;

      .serverStats__title {
        margin-bottom: 5px;
      }
    }

    @media(max-width: 800px) {
      .serverStats__content {
        flex-wrap: wrap;
      }

      .break {
        flex: 1;
      }
    }

    @media(max-width: 500px) {
      .serverStats__content {
        flex-direction: column;
      }

      .break {
        display: none;
      }

      .stat {
        flex: 1;
      }
    }
  }

  .spinner {
    width: 30px;
  }

  .title {
    margin-right: 30px;
    font-weight: 700;
  }

  .stat {
    margin-right: 10px;
    padding-right: 10px;
  }
</style>
