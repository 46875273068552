<template>
  <div class="filterOptions">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FilterOptions',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
  .filterOptions {
    margin-bottom: 1rem;

    > label:not(:first-of-type),
    > button:not(:first-child) {
      margin-left: 0.5em;
    }

    button {
      margin-bottom: 0;
    }
  }
</style>
