<template>
  <div class="header">
    <router-link to="/" class="header__logo">
      <h1>DASHLIST</h1>
    </router-link>

    <nav class="header__nav">
      <router-link to="/">SERVERS</router-link>
      <router-link to="/players">PLAYERS</router-link>
      <router-link to="/clans">TEAMS/CLANS</router-link>
      <router-link to="/info">INFO</router-link>
    </nav>
  </div>
</template>

<style lang="scss">
.header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  &__logo {
    color: black;
    font-style: italic;
    text-decoration: none;
    margin-right: 0.5rem;

    h1 {
      margin: 0;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  &__nav {
    margin-right: 0;

    a {
      padding: 0 0.5rem;
      display: inline-block;
      font-weight: bold;
      letter-spacing: 0.08ch;
      font-family: 'Oswald', sans-serif;
      color: black;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.router-link-exact-active {
        color: gray;
      }
    }
  }
}
</style>
