export function closeAllFiltersOptions (filter) {
  // Loop through filter options
  for (const option in filter) {
    // Skip filter version
    if (option === 'version') {
      continue
    }

    // Close option
    filter[option].open = false
  }
}

export function toggleFilterOption (filter, optionName) {
  // Handle filter not found
  if (!filter[optionName]) {
    return
  }

  // Toggle filter
  if (filter[optionName].open) {
    closeAllFiltersOptions(filter)
  } else {
    closeAllFiltersOptions(filter)
    filter[optionName].open = true
  }
}

export function clearFilter (filter, filterName, filterValue) {
  // Handle no filter name given
  if (!filterName) {
    // Loop through filter options
    for (const option in filter) {
      // Skip filter version
      if (option === 'version') {
        continue
      }

      // Reset filter option to default
      filter[option].value = filter[option].default
    }
    return
  }

  // Loop through filter options
  for (const option in filter) {
    // Handle current option does not match filter name
    if (option !== filterName) {
      continue
    }

    // Check filter type and perform reset
    switch (filter[option].type) {
      case 'checkbox':
        // Handle filterValue to be reset is defined
        if (filterValue) {
          filter[option].value = filter[option].value.filter(value => value !== filterValue)
          break
        }
        filter[option].value = filter[option].default
        break

      default:
        filter[option].value = filter[option].default
    }
  }
}
