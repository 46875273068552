<template>
  <div class="filterOption">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FilterOption',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .filterOption {
    margin-bottom: 0.25rem;

    label:not(:first-of-type),
    button:not(:first-child) {
      margin-left: 0.5em;
    }
  }
</style>
