<template>
  <div class="serverListItem" :class="{selected: selected}" @click="selected = !selected">
    <div class="serverListItem__info">
      <div class="title"><img v-if="server.password" src="@/assets/icons/icon-lock.svg"/><span>{{ server.name }}</span></div>
      <div class="region">{{ server.region }}</div>
      <div class="break"/>
      <div class="mode">{{ server.mode }}<span v-if="server.map">: {{ server.map }}</span></div>
      <div class="players">{{ formatedPlayerCount }}<span v-if="roundedAverage"> (avg. lvl. {{ roundedAverage }})</span></div>
      <div class="break"/>
      <div class="levelLock"><span v-if="server.minLevel !== 0 || server.maxLevel !== 0">lvl. lock ({{ server.minLevel }} – {{ server.maxLevel }})</span></div>
    </div>

    <div class="serverListItem__players" v-if="selected">
      <Player v-for="(player, key) in server.players" :key="key + player.name + player.level" :player="player" :showTeam="true" />
      <span v-if="!server.players" class="serverListItem__players__empty">This server is empty</span>
    </div>
  </div>
</template>

<script>
import Player from '@/components/Player.vue'

export default {
  name: 'ServerListItem',
  components: {
    Player
  },
  props: {
    server: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      selected: false
    }
  },
  computed: {
    formatedPlayerCount () {
      return `${this.server.players.length}/10`
    },
    roundedAverage () {
      if (!this.server.average) {
        return false
      }

      return Math.round(this.server.average * 100) / 100
    }
  }
}
</script>

<style lang="scss" scoped>
  .serverListItem {
    padding: 0.3rem 0.5rem;
    background-color: #f5f5f5;
    margin-bottom: 0.25rem;

    &:hover {
      cursor: pointer;
    }

    &__info {
      display: flex;

      @media(max-width: 800px) {
        flex-wrap: wrap;
      }
    }

    &__players {
      margin-top: 0.25rem;

      &__empty {
        color: #808080;
      }
    }

    .title {
      flex: 3;
      display: flex;

      @media(max-width: 800px) {
        flex: 1;
        font-weight: 700;
      }

      img {
        width: 15px;
        margin-right: 4px;
      }
    }

    .region {
      flex: 2;
    }

    .mode {
      flex: 3;
    }

    .players {
      flex: 2;
    }

    .levelLock {
      flex: 1.5;
    }

    @media(max-width: 800px) {
      .break {
        flex: 100%;
        display: block;
      }

      .region,
      .mode,
      .players,
      .levelLock {
        flex: 1;
      }
    }
  }
</style>
