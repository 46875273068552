<template>
  <div class="player" v-if="player">
    <span v-if="showTeam" class="player__team" :class="teamClass"/>
    <span class="player__level">{{ player.level }}</span>
    <span class="player__tag" v-if="player.tag">{{ player.tag }}</span>
    <span class="player__name">{{ player.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'Player',
  props: {
    player: Object,
    showTeam: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    teamClass () {
      const computedClass = {}
      computedClass[`player__team--${this.player.team}`] = true

      return computedClass
    }
  }
}
</script>

<style lang="scss" scoped>
.player {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.15rem;

  &__team {
    width: 0.5rem;
    margin-right: 0.15rem;
    align-self: stretch;
    display: inline-block;

    &--0 {
      background-color: #cf2e2e; // Red
    }

    &--1 {
      background-color: #0693e3; // Blue
    }

    &--2 {
      background-color: #F2D100; // Yellow
    }

    &--3 {
      background-color: #2ECC40; // Green
    }

    &--4 {
      background-color: #A70DC9; // Purple
    }

    &--5 {
      background-color: #FF851B; // Orange
    }

    &--6 {
      background-color: black; // Black
    }

    &--7 {
      background-color: white; // White
      border: solid 1px #000;
    }

    &--8 {
      background-color: #00FFFF; // Cyan
    }

    &--9 {
      background-color: #DB00A9; // Pink
    }

    &--10 {
      background-color: gray; // Gray
    }
  }

  &__level {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #fff;
  }

  &__tag {
    margin-left: 0.3rem;
    font-size: 1.15rem;
    font-weight: bold;
  }

  &__name {
    margin-left: 0.25rem;
  }
}
</style>
