<template>
  <div class="filterButton" :class="{ active: filter[optionName].open }" tabIndex="0" @click="toggleFilterOption(filter, optionName)">
    <slot />
  </div>
</template>

<script>
import { toggleFilterOption } from '@/utility/filter.js'

export default {
  name: 'FilterButton',
  data () {
    return {

    }
  },
  props: {
    filter: {
      type: Object,
      default () {
        return {}
      }
    },
    optionName: {
      type: String,
      default () {
        return ''
      }
    }
  },
  methods: {
    toggleFilterOption
  }
}
</script>

<style lang="scss" scoped>
  .filterButton {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;

    &:hover {
      cursor: pointer;
    }
  }
</style>
