<template>
  <div class="clans">
    <h1 class="title">TEAMS/CLANS</h1>
    <StatsBanner />
    <ClanList />
  </div>
</template>

<script>
import StatsBanner from '@/components/StatsBanner.vue'
import ClanList from '@/components/ClanList.vue'

export default {
  name: 'Clans',
  components: {
    StatsBanner,
    ClanList
  }
}
</script>
