import { createStore } from 'vuex'

export default createStore({
  state: {
    servers: [],
    fetching: false,
    autoFetch: false,
    autoFetchHandle: null
  },

  mutations: {
    setServers (state, data) {
      state.servers = data
    },
    setFetchState (state, data) {
      state.fetching = data
    },
    setAutoFetch (state, data) {
      state.autoFetch = data
    },
    setAutoFetchHandle (state, data) {
      state.autoFetchHandle = data
    }
  },

  actions: {
    fetchServerData (context) {
      // Set fetching state
      context.commit('setFetchState', true)

      // Fetch the API data
      fetch('https://dashlistapi.hyperdash.dev/')
        // Convert to JSON
        .then(response => response.json())
        // Commit server data to store
        .then(data => {
          // Loop through servers
          for (const serverId in data) {
            // Add server id as parameter
            data[serverId].id = serverId

            // Calculate average level
            if (!data[serverId].players.length) {
              continue
            }

            let totalPlayerLevel = 0

            for (const player of data[serverId].players) {
              totalPlayerLevel += player.level
            }

            data[serverId].average = totalPlayerLevel / data[serverId].players.length
          }

          // Convert data to array
          return Object.values(data)
        })
        .then(data => context.commit('setServers', data))
        .finally(() => {
          context.commit('setFetchState', false)
        })
    },
    enableAutoFetch (context) {
      // Set auto fetch state
      context.commit('setAutoFetch', true)

      // Create and set fauto fetch handle
      context.commit('setAutoFetchHandle', setInterval(() => {
        // Trigger fetch action
        context.dispatch('fetchServerData')
      }, 10000))

      // Fetch data right away
      context.dispatch('fetchServerData')
    },
    disableAutoFetch (context) {
      // Set auto fetch state
      context.commit('setAutoFetch', false)

      // Clear interval
      clearInterval(context.state.autoFetchHandle)

      // Reset auto fetch handle
      context.commit('setAutoFetchHandle', null)
    },
    toggleAutoFetch (context) {
      // Handle auto fetch enabled
      if (context.state.autoFetch || context.state.autoFetchHandle) {
        context.dispatch('disableAutoFetch')
        return
      }

      context.dispatch('enableAutoFetch')
    }
  },

  getters: {
    fetching (state) {
      return state.fetching
    },
    autoFetch (state) {
      return state.autoFetch
    },
    servers (state) {
      // Return servers
      return state.servers
    },
    regions (state) {
      const foundRegions = []

      // Loop through server list
      for (const server of state.servers) {
        // Handle region is already found
        if (foundRegions.includes(server.region)) {
          continue
        }

        // Add region to list
        foundRegions.push(server.region)
      }

      // Sort array
      foundRegions.sort()

      // Return regions
      return foundRegions
    },
    modes (state) {
      const foundModes = []

      // Loop through server list
      for (const server of state.servers) {
        // Handle mode is already found
        if (foundModes.includes(server.mode)) {
          continue
        }

        // Add mode to list
        foundModes.push(server.mode)
      }

      // Sort array
      foundModes.sort()

      // Return modes
      return foundModes
    },
    players (state) {
      // Create array to store players in
      let playerList = []

      // Handle no servers available
      if (!state.servers) {
        return playerList
      }

      // Loop through server list
      for (const server of state.servers) {
        // Handle server has no players
        if (!server.players) {
          continue
        }

        // Add players to array
        playerList = playerList.concat(server.players)
      }

      // Return players
      return playerList
    },
    clans (state) {
      // Create array to store clans in
      const clanList = []

      // Handle no servers available
      if (!state.servers) {
        return clanList
      }

      // Loop through server list
      for (const server of state.servers) {
        // Handle server has no clans
        if (!server.players) {
          continue
        }

        // Loop through players
        for (const player of server.players) {
          // Handle player has no clan tag
          if (!player.tag) {
            continue
          }

          let clanInList = false

          // Loop through clanlist
          for (const clan of clanList) {
            // Handle clan name matches current player tag
            if (clan.name === player.tag) {
              clanInList = true

              // Add player to list of players in clan
              clan.players.push(player)
            }
          }

          // Handle clan was not found
          if (!clanInList) {
            // Add clan to list
            clanList.push({
              name: player.tag,
              players: [
                player
              ]
            })
          }
        }
      }

      // Return clans
      return clanList
    }
  }
})
