<template>
  <div class="info">
    <h1 class="title">INFO</h1>
    <section class="section">
      <p>DASHLIST is a server/player/clan browser for <a href="https://www.hyperdashvr.com/">Hyper Dash</a> made by tim9009. All data shown is based on server data from Hyper Dash updated every 10 seconds. The website is built on the Dash Index API made by Sirvoid.</p>
      <p>Feel free to reach out to me on discord for any feedback, ideas or bug reports.</p>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Info'
}
</script>
