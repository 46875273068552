<template>
  <section class="playerList">
    <Filter>
      <FilterButtons>
        <input v-model="filter.search.value" type="text" class="search" :class="{ active: filter.search.open }" placeholder="Search..." />

        <select v-model="filter.clan.value" class="clan" :class="{ active: filter.clan.open }">
          <option value="">All clans</option>
          <option value="noClan">No clan tag</option>
          <option v-for="clan in sortedClans" :key="clan.name" :value="clan.name">{{ clan.name }}</option>
        </select>
      </FilterButtons>

      <FilterTags :filter="filter" />
    </Filter>

    <div class="stats">
      <span v-if="filter.search || filter.clan">Number of matching players: </span>
      <span v-else>Total number of players online: </span>
      <b>{{ players.length }}</b>
    </div>

    <div class="players">
      <div v-for="(player, key) in players" :key="key + player.name + player.level" class="playerWrapper">
        <Player :player="player" />
      </div>
    </div>
  </section>
</template>

<script>
import Filter from '@/components/filter/Filter.vue'
import FilterButtons from '@/components/filter/FilterButtons.vue'
import FilterTags from '@/components/filter/FilterTags.vue'
import Player from '@/components/Player.vue'

export default {
  name: 'ServerList',
  components: {
    Filter,
    FilterButtons,
    FilterTags,
    Player
  },
  props: {
    msg: String
  },
  data () {
    return {
      filter: {
        version: '2',
        search: {
          value: '',
          type: 'text',
          default: '',
          open: false
        },
        clan: {
          value: '',
          type: 'select',
          default: '',
          open: false
        }
      }
    }
  },
  computed: {
    players () {
      // Handle server data not set
      if (!this.$store || !this.$store.getters.players) {
        return {}
      }

      // Make variable of filtered players
      let filteredPlayers = this.$store.getters.players

      // Handle search filter
      if (this.filter.search.value) {
        filteredPlayers = filteredPlayers.filter((player) => {
          return player.name.toLowerCase().includes(this.filter.search.value.toLowerCase()) || player.tag.toLowerCase().includes(this.filter.search.value.toLowerCase())
        })
      }

      // Handle clan filter
      if (this.filter.clan.value) {
        filteredPlayers = filteredPlayers.filter((player) => {
          if (this.filter.clan.value === 'noClan') {
            return !player.tag
          }

          return player.tag && player.tag === this.filter.clan.value
        })
      }

      // Sort players by level
      filteredPlayers.sort((a, b) => {
        return b.level - a.level
      })

      return filteredPlayers
    },
    sortedClans () {
      const clans = this.$store.getters.clans
      return clans.sort((a, b) => {
        return b.players.length - a.players.length
      })
    }
  },
  mounted () {
    // Handle filter is set in local storage
    if (localStorage.playerFilter) {
      // Get stored filter
      const filter = JSON.parse(localStorage.playerFilter)

      // Check if filter version is up to date
      if (filter.version && filter.version === this.filter.version) {
        // Overwrite filter with stored filter
        this.filter = filter
      }
    }

    this.$store.dispatch('fetchServerData')
  },
  watch: {
    filter: {
      deep: true,
      handler (newFilter) {
        // Store new filter settings
        localStorage.playerFilter = JSON.stringify(newFilter)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .players {
    display: flex;
    flex-wrap: wrap;
  }

  .playerWrapper {
    width: calc(100% / 3);

    @media(max-width: 900px) {
      width: 50%;
    }

    @media(max-width: 530px) {
      width: 100%;
    }
  }

  .search {
    flex: 10;
  }

  .clan {
    flex: 2;
  }

  .stats {
    margin-bottom: 0.5rem;
  }

  @media(max-width: 750px) {
    .clan {
      flex: 5;
    }

    .break {
      display: block;
      width: 100%;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
    }
  }
</style>
