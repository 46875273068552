<template>
  <div class="filterButtons">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FilterButtons',
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
  .filterButtons {
    margin-bottom: 15px;
    display: flex;

    > * {
      height: 2.5rem;
      margin: 0;
      padding: 0.25rem 0.5rem;
      border-radius: 0;
      border: solid 1px #000;
      background-color: #fff;
      -moz-appearance: none;
      -webkit-appearance: none !important;
      appearance: none;
      color: #000;

      &:not(:last-child) {
        border-right: none;
      }

      &.active {
        background-color: #333;
        color: #fff;
      }
    }

    @media(max-width: 750px) {
      flex-wrap: wrap;
    }
  }
</style>
