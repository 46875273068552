<template>
  <div class="players">
    <h1 class="title">PLAYERS</h1>
    <StatsBanner />
    <PlayerList />
  </div>
</template>

<script>
import StatsBanner from '@/components/StatsBanner.vue'
import PlayerList from '@/components/PlayerList.vue'

export default {
  name: 'Players',
  components: {
    StatsBanner,
    PlayerList
  }
}
</script>
