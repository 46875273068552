<template>
  <div class="filter">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Filter',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
</style>
