<template>
  <div class="footer">
    Website by tim9009 | API by Sirvoid | By and for the Hyper Dash community | version 2.4.0
  </div>
</template>

<style lang="scss">
.footer {
  width: 100%;
  padding: 1rem 2rem;
  text-align: center;
  color: #808080;
}
</style>
