<template>
  <div v-if="filterTags.length" class="activeFilters">
    <div v-for="filterTag in filterTags" :key="filterTag.text" @click="clearFilter(filter, filterTag.type, filterTag.text)" class="filterTag" tabindex="0">{{ filterTag.text }}</div>
    <div @click="clearFilter(filter)" class="filterTag" tabindex="0">Clear filter</div>
  </div>
</template>

<script>
import { clearFilter } from '@/utility/filter.js'

export default {
  name: 'FilterTags',
  props: {
    filter: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    filterTags () {
      const tags = []

      // Search
      if (this.filter.search && this.filter.search.value) {
        tags.push({ text: `Search: "${this.filter.search.value}"`, type: 'search' })
      }

      // Regions
      if (this.filter.regions && this.filter.regions.value && this.filter.regions.value.length) {
        for (const region of this.filter.regions.value) {
          tags.push({ text: region, type: 'regions' })
        }
      }

      // Modes
      if (this.filter.modes && this.filter.modes.value && this.filter.modes.value.length) {
        for (const mode of this.filter.modes.value) {
          tags.push({ text: mode, type: 'modes' })
        }
      }

      // Players
      if (this.filter.players && this.filter.players.value && (this.filter.players.value[0] !== 0 || this.filter.players.value[1] !== 10)) {
        tags.push({ text: `${this.filter.players.value[0]} to ${this.filter.players.value[1]} players`, type: 'players' })
      }

      // Privacy
      if (this.filter.privacy && this.filter.privacy.value) {
        for (const privacy of this.filter.privacy.value) {
          tags.push({ text: privacy, type: 'privacy' })
        }
      }

      // Level lock
      if (this.filter.levelLock && this.filter.levelLock.value && (this.filter.levelLock.value[0] > 0 || this.filter.levelLock.value[1] < 100)) {
        tags.push({ text: `Level lock ${this.filter.levelLock.value[0]} to ${this.filter.levelLock.value[1]}`, type: 'levelLock' })
      }

      // Clan
      if (this.filter.clan && this.filter.clan.value) {
        tags.push({ text: this.filter.clan.value, type: 'clan' })
      }

      // Official tags
      if (this.filter.officialTags && this.filter.officialTags.value) {
        tags.push({ text: 'Only official tags', type: 'officialTags' })
      }

      return tags
    }
  },
  methods: {
    clearFilter
  }
}
</script>

<style lang="scss" scoped>
  .activeFilters {
    width: 100%;
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .filterTag {
    margin: 0.25rem 0.25rem 0 0;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    background-color: #333;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
</style>
