<template>
  <Header />
  <main class="container">
    <router-view />
  </main>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  mounted () {
    this.$store.dispatch('enableAutoFetch')
  },
  beforeUnmount () {
    this.$store.dispatch('disableAutoFetch')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
