<template>
  <section class="clanList">
    <Filter>
      <FilterButtons>
        <input v-model="filter.search.value" type="text" class="search" :class="{ active: filter.search.value }" placeholder="Search..." />
        <FilterButton :filter="filter" option-name="officialTags" class="officialTags">Official tags</FilterButton>
      </FilterButtons>

      <FilterOptions v-show="filter.officialTags.open">
        <FilterOption>
          <input id="privacyPublic" type="checkbox" v-model="filter.officialTags.value">
          <label for="privacyPublic"><span>Only show "official tags" and tags currently used by multiple players</span></label>
        </FilterOption>
      </FilterOptions>
    </Filter>

    <FilterTags :filter="filter" />

    <div class="clanList__stats">
      <span v-if="filter.search || filter.officialTags">Number of matching unique clan tags online: </span>
      <span v-else>Number of unique clan tags online: </span>
      <b>{{ clans.length }}</b>
    </div>

    <div class="clans">
      <div v-for="clan in clans" :key="clan.name" class="clanWrapper">
        <h2>{{ clan.name }}</h2>
        <Player v-for="player in clan.players" :key="player.name + player.level" :player="player" />
      </div>
    </div>
  </section>
</template>

<script>
import Filter from '@/components/filter/Filter.vue'
import FilterButtons from '@/components/filter/FilterButtons.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import FilterOptions from '@/components/filter/FilterOptions.vue'
import FilterOption from '@/components/filter/FilterOption.vue'
import FilterTags from '@/components/filter/FilterTags.vue'
import Player from '@/components/Player.vue'

export default {
  name: 'ClanList',
  components: {
    Filter,
    FilterButtons,
    FilterButton,
    FilterOptions,
    FilterOption,
    FilterTags,
    Player
  },
  props: {
    msg: String
  },
  data () {
    return {
      filter: {
        version: '2',
        search: {
          value: '',
          type: 'text',
          default: '',
          open: false
        },
        officialTags: {
          value: true,
          type: 'boolean',
          default: false,
          open: false
        }
      }
    }
  },
  computed: {
    clans () {
      // Handle server data not set
      if (!this.$store || !this.$store.getters.clans) {
        return {}
      }

      const officialTags = [
        // TEAMS
        '-O-',
        'ARC',
        'BL%D',
        'blck',
        'BLTZ',
        'BNDT',
        'D',
        'DARK',
        'EMU',
        'F1RE',
        'FaiR',
        'FAKE',
        'FeaR',
        'FuRy',
        'HAXX',
        'HHI',
        'HOTU',
        'IRON',
        'L1FE',
        'LMB0',
        'LOOP',
        'MOST',
        'org',
        'REAL',
        'SKY',
        'SPNV',
        'STHX',
        'TBDi',
        'the',
        'TMNM',
        'UNSC',
        'guh',
        'HDRA',
        'KNHT',
        'R',
        'THVS',
        'ENVY',

        // CLANS
        'dark',
        'clnm',
        'CLNM',
        'hh',
        'HH',
        '9V',
        'nv'
      ]

      let clans = this.$store.getters.clans

      // Handle search filter active
      if (this.filter.search && this.filter.search.value) {
        // Convert search result to lower case
        const searchTerm = this.filter.search.value.toLowerCase()

        // Filter by search
        clans = clans.filter((clan) => {
          // Check if search matches server name
          if (clan.name.toLowerCase().includes(searchTerm)) {
            return true
          }

          // Check if search matches a player tag or name
          if (clan.players) {
            for (const player of clan.players) {
              if (player.tag.toLowerCase().includes(searchTerm) || player.name.toLowerCase().includes(searchTerm)) {
                return true
              }
            }
          }

          // Return no matching results
          return false
        })
      }

      // Filter by official tags
      if (this.filter.officialTags.value) {
        clans = clans.filter((clan) => {
          return clan.players.length >= 2 || officialTags.includes(clan.name)
        })
      }

      // Sort tags by player number
      clans.sort((a, b) => {
        return b.players.length - a.players.length
      })

      return clans
    }
  },
  mounted () {
    // Handle filter is set in local storage
    if (localStorage.clanFilter) {
      // Get stored filter
      const filter = JSON.parse(localStorage.clanFilter)

      // Check if filter version is up to date
      if (filter.version && filter.version === this.filter.version) {
        // Overwrite filter with stored filter
        this.filter = filter
      }
    }

    this.$store.dispatch('fetchServerData')
  },
  watch: {
    filter: {
      deep: true,
      handler (newFilter) {
        // Store new filter settings
        localStorage.clanFilter = JSON.stringify(newFilter)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .clans {
    display: flex;
    flex-wrap: wrap;
  }

  .clanWrapper {
    width: calc(100% / 3);

    @media(max-width: 900px) {
      width: 50%;
    }

    @media(max-width: 530px) {
      width: 100%;
    }
  }

  .search {
    flex: 10;
  }

  .officialTags {
    flex: 2;
  }

  .stats {
    margin-bottom: 0.5rem;
  }

  @media(max-width: 750px) {
    .officialTags {
      flex: 5;
    }

    .break {
      display: block;
      width: 100%;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
    }
  }
</style>
