import { createRouter, createWebHistory } from 'vue-router'
import Servers from '../views/Servers.vue'
import Players from '../views/Players.vue'
import Clans from '../views/Clans.vue'
import Info from '../views/Info.vue'

const routes = [
  {
    path: '/',
    name: 'Servers',
    component: Servers
  },
  {
    path: '/players',
    name: 'Players',
    component: Players
  },
  {
    path: '/clans',
    name: 'Clans',
    component: Clans
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
